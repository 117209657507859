


































































import { CONDITIONS_ICON, CONDITIONS_TYPE } from "@/helpers/conditions";
import { Equipment } from "@/models/entities/equipment.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CEquipments extends Vue {
  @Prop({ required: false, default: "", type: String })
  private employeeFullName: string;
  @Prop({ required: false, default: null, type: Object })
  private equipmentAnswers: { [id: string]: Equipment[] };

  private get equipmentNames(): string[] {
    return this.equipmentAnswers ? Object.keys(this.equipmentAnswers) : [];
  }

  private getChipIcon(value: string): string {
    return CONDITIONS_ICON(this.chipClass(value));
  }

  private getChipType(value: string): string {
    return CONDITIONS_TYPE(this.chipClass(value));
  }

  private chipClass(value: string): string {
    if (value) {
      switch (value.toLowerCase()) {
        case "si":
          return "success";
        case "no":
          return "error";
        default:
          return null;
      }
    }
    return null;
  }

  private getImgSrc(value: string): string {
    if (value) {
      switch (value.toLowerCase()) {
        case "si":
          return "check";
        case "no":
          return "error";
        default:
          return "none";
      }
    }
    return "none";
  }
}
